import { NativeModules, Platform } from 'react-native';

const LINKING_ERROR =
  `The package 'react-native-clarity' doesn't seem to be linked. Make sure: \n\n` +
  Platform.select({
    ios: "- You have run 'pod install --repo-update'\n",
    default: '',
  }) +
  '- You rebuilt the app after installing the package\n' +
  '- You are not using Expo Go\n';

const Clarity = NativeModules.Clarity;

const SupportedPlatforms = ['android'];

let SupportWarningWasShown = false;

/**
 * The configuration that will be used to customize the Clarity behaviour.
 *
 * @param userId        [OPTIONAL default = null] A custom identifier for the current user. If passed as null, the user id
 *                      will be auto generated. The user id in general is sticky across sessions.
 *                      The provided user id must follow these conditions:
 *                          1. Cannot be an empty string.
 *                          2. Should be base36 and smaller than "1Z141Z4".
 * @param logLevel      [OPTIONAL default = LogLevel.None] The level of logging to show in the device logcat stream.
 * @param allowMeteredNetworkUsage  [OPTIONAL default = false] Allows uploading session data to the servers on device metered network.
 * @param enableWebViewCapture    [OPTIONAL default = true] Allows Clarity to capture the web views DOM content.
 * @param allowedDomains    [OPTIONAL default = ["*"]] The whitelisted domains to allow Clarity to capture their DOM content.
 *                          If it contains "*" as an element, all domains will be captured.
 *                          Note: iOS currently does not support allowedDomains feature.
 * @param disableOnLowEndDevices [OPTIONAL default = false] Disable Clarity on low-end devices.
 * @param maximumDailyNetworkUsageInMB [OPTIONAL default = null] Maximum daily network usage for Clarity (null = No limit). When the limit is reached, Clarity will turn on lean mode.
 *                                      Note: iOS currently does not support limiting network usage.
 * @param enableIOS_experimental [OPTIONAL default = false] Experimental flag to enable Clarity on iOS platform.
 */
export interface ClarityConfig {
  userId?: string | null;
  logLevel?: LogLevel;
  allowMeteredNetworkUsage?: boolean;
  enableWebViewCapture?: boolean;
  allowedDomains?: string[];
  disableOnLowEndDevices?: Boolean;
  maximumDailyNetworkUsageInMB?: number;
  enableIOS_experimental?: boolean;
}

/**
 * The level of logging to show in the device logcat stream.
 */
export enum LogLevel {
  Verbose = 'Verbose',
  Debug = 'Debug',
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
  None = 'None',
}

function isClarityUnavailable(): boolean {
  if (!SupportedPlatforms.includes(Platform.OS)) {
    let warningMessage = 'Clarity supports ' + SupportedPlatforms.join(', ') + ' only for now.';
    if (Platform.OS === 'ios') {
      warningMessage = `${warningMessage} To enable experimental iOS support, set the 'enableIOS_experimental' config value to true.`;
    }

    if (!SupportWarningWasShown) {
      console.warn(warningMessage);
      SupportWarningWasShown = true;
    }

    return true;
  }

  if (Clarity === null) {
    console.error('Clarity did not initialize properly.', LINKING_ERROR);
    return true;
  }

  return false;
}

/**
 * Initializes the Clarity SDK if the API level is supported.
 * @param projectId     [REQUIRED] The Clarity project id to send data to.
 * @param config   [OPTIONAL] The clarity config, if not provided default values are used.
 */
export function initialize(projectId: string, config?: ClarityConfig) {
  if (
    typeof projectId !== 'string' ||
    !(typeof config === 'object' || typeof config === 'undefined')
  ) {
    throw Error(
      'Invalid Clarity initialization arguments. Please check the docs for assitance.'
    );
  }

  // applying default values
  let {
    userId = null,
    logLevel = LogLevel.None,
    allowMeteredNetworkUsage = false,
    enableWebViewCapture = true,
    allowedDomains = ['*'],
    disableOnLowEndDevices = false,
    maximumDailyNetworkUsageInMB = null,
    enableIOS_experimental = false,
  } = config ?? {};

  if (enableIOS_experimental === true && !SupportedPlatforms.includes('ios')) {
    SupportedPlatforms.push('ios');
  }

  if (isClarityUnavailable()) {
    return;
  }

  // We use two parameters because the react method parameters do not accept nullable primitive types.
  let enableDailyNetworkUsageLimit = maximumDailyNetworkUsageInMB != null;
  let refinedMaximumDailyNetworkUsageInMB = maximumDailyNetworkUsageInMB ?? 0;

  Clarity.initialize(
    projectId,
    userId,
    logLevel,
    allowMeteredNetworkUsage,
    enableWebViewCapture,
    allowedDomains,
    disableOnLowEndDevices,
    enableDailyNetworkUsageLimit,
    refinedMaximumDailyNetworkUsageInMB
  );
}

/**
 * Pauses the Clarity capturing processes until the next resume() is called.
 */
export function pause(): Promise<boolean | undefined> {
  if (isClarityUnavailable()) {
    return Promise.resolve(undefined);
  }

  return Clarity.pause();
}

/**
 * Resumes the Clarity capturing processes if they are not already resumed.
 * Note: Clarity starts capturing data right on initialization.
 */
export function resume(): Promise<boolean | undefined> {
  if (isClarityUnavailable()) {
    return Promise.resolve(undefined);
  }

  return Clarity.resume();
}

/**
 * Returns true if Clarity has been paused by the user.
 */
export function isPaused(): Promise<Boolean | undefined> {
  if (isClarityUnavailable()) {
    return Promise.resolve(undefined);
  }

  return Clarity.isPaused();
}

/**
 * Sets a custom user id that can be used to identify the user. It has less
 * restrictions than the userId parameter. You can pass any string and
 * you can filter on it on the dashboard side. If you need the most efficient
 * filtering on the dashboard, use the userId parameter if possible.
 * <p>
 * Note: custom user id cannot be null or empty, or consists only of whitespaces.
 * </p>
 * @param customUserId   The custom user id to set.
 */
export function setCustomUserId(customUserId: string): Promise<boolean | undefined> {
  if (isClarityUnavailable()) {
    return Promise.resolve(undefined);
  }

  return Clarity.setCustomUserId(customUserId);
}

/**
 * Sets a custom session id that can be used to identify the session.
 * <p>
 * Note: custom session id cannot be null or empty, or consists only of whitespaces.
 * </p>
 * @param customSessionId   The custom session id to set.
 */
export function setCustomSessionId(customSessionId: string): Promise<boolean | undefined> {
  if (isClarityUnavailable()) {
    return Promise.resolve(undefined);
  }

  return Clarity.setCustomSessionId(customSessionId);
}

/**
 * Sets a custom tag for the current session.
 * @param key   The tag key to set.
 * @param value   The tag value to set.
 */
export function setCustomTag(key: string, value: string): Promise<boolean | undefined> {
  if (isClarityUnavailable()) {
    return Promise.resolve(undefined);
  }

  return Clarity.setCustomTag(key, value);
}

/**
 * For React Native applications only, this function is used to set the current screen name
 * in case the ReactNative Navigation package is used.
 * This will allow you to split and analyze your data on the screen names.
 * You can it set to `null` to remove the latest set value.
 * @param screenName   The current screen name to set.
 */
export function setCurrentScreenName(
  screenName: string | null
): Promise<boolean | undefined> {
  if (isClarityUnavailable()) {
    return Promise.resolve(undefined);
  }

  return Clarity.setCurrentScreenName(screenName);
}

/**
 * Returns the active session id. This can be used to correlate the Clarity session with other
 * analytics tools that the developer may be using.
 * @returns a promise that resolves to the current session id.
 */
export function getCurrentSessionId(): Promise<string> {
  if (isClarityUnavailable()) {
    return Promise.resolve('Undefined');
  }

  return Clarity.getCurrentSessionId();
}

/**
 * Returns the active session url. This can be used to correlate the Clarity session with other
 * analytics tools that the developer may be using.
 *
 * @returns a promise that resolves to the current session url if there is an active one.
 */
export function getCurrentSessionUrl(): Promise<string> {
  if (isClarityUnavailable()) {
    return Promise.resolve('Undefined');
  }

  return Clarity.getCurrentSessionUrl();
}
